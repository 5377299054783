<template>
  <section class="technical-drawing__section th-our-story CitasAviso">
    <div
      class="th-our-story__inner"
      style="padding:32px 0"
    >
      <div class="container mt24">
        <h2 class="type-service__heading heading">Ya puedes programar tu cita médica</h2>
        <div class="type-service__text">Programa tu cita médica ya sea en la unidad médica de tu preferencia o podemos acudir a tu domicilio</div>
        <ul class="type-service__list row">
          <li class="type-service__item col-12 col-md">
            <router-link to="/citas">
              <div class="type-service__item-icon type-service__item-icon--interior">
                <svg
                  width="49"
                  height="49"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M0 0h24v24H0z"
                    fill="none"
                  />
                  <path d="M19 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 11h-4v4h-4v-4H6v-4h4V6h4v4h4v4z" />
                </svg>
              </div>
              <h3 class="type-service__item-heading">En unidad médica</h3>
              <div class="type-service__item-text">Programa tu cita en la hora y unidad médica que sea de tu mayor conveniencia.</div>
            </router-link>
          </li>
          <li class="type-service__item col-12 col-md">
            <router-link to="/citas">
              <div class="type-service__item-icon type-service__item-icon--interior">
                <svg
                  width="49"
                  height="49"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M0,0h24v24H0V0z"
                    fill="none"
                  />
                  <polygon points="1,11 1,21 6,21 6,15 10,15 10,21 15,21 15,11 8,6" />
                  <path d="M10,3v1.97l7,5V11h2v2h-2v2h2v2h-2v4h6V3H10z M19,9h-2V7h2V9z" />
                </svg>
              </div>
              <h3 class="type-service__item-heading">En el domiclio indicado</h3>
              <div class="type-service__item-text">Acudimos al domicilio en la fecha y hora que nos indiques para tu mayor comodidad</div>
            </router-link>
          </li>
          <li class="type-service__item col-12 col-md">

            <div class="type-service__item-icon type-service__item-icon--interior">
              <svg
                width="64"
                height="64"
                viewBox="0 0 24 24"
              >
                <path d="M19.5,6c-1.31,0-2.37,1.01-2.48,2.3C15.14,7.8,14.18,6.5,12,6.5c-2.19,0-3.14,1.3-5.02,1.8C6.87,7.02,5.81,6,4.5,6 C3.12,6,2,7.12,2,8.5V9c0,6,3.6,7.81,6.52,7.98C9.53,17.62,10.72,18,12,18s2.47-0.38,3.48-1.02C18.4,16.81,22,15,22,9V8.5 C22,7.12,20.88,6,19.5,6z M3.5,9V8.5c0-0.55,0.45-1,1-1s1,0.45,1,1v3c0,1.28,0.38,2.47,1.01,3.48C4.99,14.27,3.5,12.65,3.5,9z M20.5,9c0,3.65-1.49,5.27-3.01,5.98c0.64-1.01,1.01-2.2,1.01-3.48v-3c0-0.55,0.45-1,1-1s1,0.45,1,1V9z M10.69,10.48 c-0.44,0.26-0.96,0.56-1.69,0.76V10.2c0.48-0.17,0.84-0.38,1.18-0.58C10.72,9.3,11.23,9,12,9s1.27,0.3,1.8,0.62 c0.34,0.2,0.71,0.42,1.2,0.59v1.04c-0.75-0.21-1.26-0.51-1.71-0.78C12.83,10.2,12.49,10,12,10C11.51,10,11.16,10.2,10.69,10.48z" />
              </svg>
            </div>
            <h3 class="type-service__item-heading">Siempre usa tu cubreboca</h3>
            <div class="type-service__item-text">Por tu seguridad y la de nuestros colaboradores es importante siempre portes tu cubrebocas </div>

          </li>
        </ul>
        <router-link
          class="th-hero-slider__btn btn btn--technical mt24 mb24"
          to="/citas"
        >Programa tu cita médica aquí</router-link>
        <br><br>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CitasAviso",
};
</script>