<template>
  <article class="service-page">
    <citas-aviso />
    <header class="service-page__header">
      <div class="container">
        <h1 class="service-page__heading heading">Unidades médicas</h1>
      </div>
    </header>
    <div
      v-for="(um,i) in unidadesMedicas"
      :key="i"
    >
      <header class="unidad-medica__header container">
        <div class="unidad-medica__inner row">
          <div class="col-12 col-md-4">
            <h1 class="unidad-medica__heading heading">{{um.nombre}}</h1>
          </div>
          <div class="col-12 col-md-6 offset-md-2">
            <ul class="unidad-medica__meta unidad-medica-meta">
              <li :class="classes.item">
                <div :class="classes.title">Ubicación</div>
                <div
                  :class="classes.text"
                  v-html="um.ubicacion"
                >
                </div>
              </li>
              <li :class="classes.item">
                <div :class="classes.title">Horario</div>
                <div :class="classes.text">{{um.horario}}
                </div>
              </li>
              <li :class="classes.item">
                <div :class="classes.title">Teléfono</div>
                <div :class="classes.text">
                  <a
                    class="unidad-medica__is-link"
                    :href="'tel:'+um.tellink"
                    v-text="um.telefono"
                  />
                </div>
              </li>
              <li :class="classes.item">
                <div :class="classes.title"></div>
                <div :class="classes.text">
                  <a
                    class="th-hero-slider__btn btn btn--technical"
                    :href="um.link"
                    target="_blank"
                  >Como llegar</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <hr v-if="i+1 < unidadesMedicas.length">
      <br>
    </div>
  </article>
</template>
<script>
import CitasAviso from "../components/CitasAviso.vue";
export default {
  components: { CitasAviso },
  name: "UnidadesMedicas",
  data() {
    return {
      classes: {
        item: "unidad-medica-meta__item",
        title: "unidad-medica-meta__item-title",
        text: "unidad-medica-meta__item-text",
      },
      unidadesMedicas: [
        {
          nombre: "Teófilo Borunda",
          ubicacion:
            "Teófilo Borunda 2400, <br>Jardines del Santuario, <br>Chihuahua, Chih.",
          horario: "24 horas",
          telefono: "614 411 9500",
          tellink: "+526144119500",
          link: "https://goo.gl/maps/Etf2p5a7QzcatS8W6",
        },
        {
          nombre: "Juan Escutia",
          ubicacion: "Av Juan Escutia 1905, <br>Nte I,<br>Chihuahua, Chih.",
          horario: "24 horas",
          telefono: "614 417 9765",
          tellink: "+526144179765",
          link: "https://goo.gl/maps/DSWgydfYyPiU3wR36",
        },
        {
          nombre: "Paseos",
          ubicacion:
            "Paseo de Arareco 15101,<br>Paseos de Chihuahua,<br>Chihuahua, Chih.",
          horario: "24 horas",
          telefono: "614 483 0333",
          tellink: "+526144830333",
          link: "https://goo.gl/maps/oudGexS1MbQpZGop8",
        },
        {
          nombre: "Fuerza Aerea",
          ubicacion:
            "Valle de los Hongos<br><small>Av. Fuerza Area Mexicana casi esquina con Carreterea Aldama.</small><br>Chihuahua, Chih.",
          horario: "24 horas",
          telefono: "614 411 1558",
          tellink: "+526144111558",
          link: "https://maps.app.goo.gl/powr7SsSzhA56UUz9",
        },
      ],
    };
  },
};
</script>